<template lang="pug">
  DeleteAlert(
    :item="item",
    :fields="fields",
    loading="ExchangeRate/getExchange"
    :tableName="$t('ExchangeRate.remove_TableName')",
    @delegateOnAlertRemoveOk="itemRemove"
  )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'ExpenseRemove',

  data () {
    return {
      fields: [{
        name: 'code',
        is_visible: true,
        short_title: this.$t('Exchange.code'),
        title: this.$t('Exchange.code')
      },
      {
        name: 'currencyName',
        is_visible: true,
        short_title: this.$t('Exchange.currencyName'),
        title: this.$t('Exchange.currencyName')
      },
      {
        name: 'conversionDate',
        is_visible: true,
        type: 'date',
        short_title: this.$t('Exchange.conversionDate'),
        title: this.$t('Exchange.conversionDate')
      },
      {
        name: 'rate',
        is_visible: true,
        short_title: this.$t('Exchange.rate'),
        title: this.$t('Exchange.rate')
      }],
      item: {}
    }
  },

  computed: {
    isLoading () {
      return this.$wait.is(['exchangeRemove'])
    }
  },
  async mounted () {
    this.getExchange(this.$route.params.id)
      .then(res => {
        this.item = res.data.exchangeRate
        this.item.conversionDate = moment(this.item.conversionDate).format('DD/MM/YYYY')
      })
    
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Exchange', [
      'deleteExchange',
      'getExchange'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      await this.deleteExchange(this.item.id).then(async res => {
        if (res) {
          const message = this.$t('Exchange.notification_Deleted')
          this.notifyShow({ message })
          this.$emit('getList')
          this.close()
        }
      })
    }, 'exchangeRemove')
  }
}
</script>
