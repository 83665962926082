<template lang="pug">
  Popup
    template(slot="title")
      template 
       | {{ $t('Exchange.form_CreateTitle') }}

    template(slot="content")
      form.Form(
        id="form-exchange"
        @submit.prevent="onSubmitForm"
      )

        .Form-item.required
          .control
            label.Form-item-label {{ $t('Global.fromCurrency') }}
            CustomSelect(
              name="fromCurrency"
              v-model="form.currencyDefinitonId"
              :optionData="filteredCurrencyList"
              v-validate="'required'"
              optionTitle="name"
              selectedEmitValue="id"
              componentName="exchange-from-currency-select"
              :isDefaultTextActive="false"
              :defaultTitle="$t('Register.formFieldCurrencySelect')"
              :data-vv-as="$t('Global.fromCurrency')"
              :isSearchActive="true"
              :error="veeErrors.has('currency')"
            )

            showValidateError(
              v-show="veeErrors.has('currency')"
              :errorName="veeErrors.first('currency')"
            )

        .Form-item.required
          .control
            label.Form-item-label {{ $t('Global.toCurrency') }}
            CustomSelect(
              name="toCurrency"
              v-model="form.toCurrencyDefinitionId"
              :optionData="filteredCurrencyList"
              v-validate="'required'"
              optionTitle="name"
              selectedEmitValue="id"
              componentName="exchange-to-currency-select"
              :isDefaultTextActive="false"
              :defaultTitle="$t('Register.formFieldCurrencySelect')"
              :data-vv-as="$t('Global.toCurrency')"
              :isSearchActive="true"
              :error="veeErrors.has('currency')"
            )

            showValidateError(
              v-show="veeErrors.has('currency')"
              :errorName="veeErrors.first('currency')"
            )

        .Form-item.required
          label.Form-item-label {{ $t('Exchange.popup_Date') }}
          .control.form-item-select
            CustomDatepicker(
              name="exchangeDate",
              :data-vv-as="$t('Exchange.popup_Date') ",
              v-model="form.conversionDate"
              :class="{ 'is-danger': veeErrors.has(`exchangeDate`) }"
              v-validate="'required'"
              pickerType="manuel",
              inputIconRight="icon-datepicker-big"
              inputIcon="",
              :disabledStartDate="disableDate")

            showValidateError(
              v-show="veeErrors.has('exchangeDate')"
              :errorName="veeErrors.first('exchangeDate')"
            )

        .Form-item.required
          label.Form-item-label {{ $t('Exchange.popup_Rate') }}
          .control.form-item-select
            custom-number-input.rate-input(
              id="exchange-rate"
              name="rate"
              v-model="form.rate"
              :data-vv-as="$t('Exchange.popup_FormField_Rate')"
              :placeholder="$t('Exchange.popup_FormField_Rate')"
              :error="veeErrors.has('rate')"
              v-validate="'greater_than:0|required'"
            )

            showValidateError(
              v-show="veeErrors.has('rate')"
              :errorName="veeErrors.first('rate')"
            )
      
        
    template(slot="footer")
      div.add-other-items-checkbox-container
        CustomCheckbox(
        id="checkbox-expense-save-and-new"
        :label="$t('Global.formSaveAndNewText', { form: $t('Exchange.form_Name') })"
        v-model="saveAndNew")

      Button(
        primary
        id="btn-exchange-form-submit"
        variant="full"
        size="medium"
        type="submit",
        form="form-exchange"
        :disabled="isLoading"
        :isLoading="isLoading"
      )
        span(
          v-show="!isLoading"
        )
          template {{ $t('Exchange.form_FinishCreateButton') }}
   
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import CustomSelect from '@/view/global/custom-select'

export default {
  name: 'OtherExpenseForm',

  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  data () {
    return {
      form: {
        rate: null,
        currencyDefinitonId: null,
        conversionDate: new Date(),
        toCurrencyDefinitionId: null
      },
      disableDate: {
        from: moment()._d
      },
      saveAndNew: true
    }
  },

  components: {
    CustomSelect
  },

  async mounted () {
    this.getCurrencyList()
  },

  computed: {

    ...mapGetters('Global', [
      'CurrencyList'
    ]),

    ...mapGetters('Auth', ['Tenant']),

    isLoading () {
      return this.$wait.is(['createExchange'])
    },

    filteredCurrencyList () {
      const list = [...this.CurrencyList]
      return list
    }
  },

  methods: {
    ...mapActions('Exchange', [
      'createExchange'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Global', [
      'getCurrencyList'
    ]),

    ...mapActions('Auth', [
      'getTenant'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },


    resetForm () {
      this.form = {
        rate: null,
        currencyDefinitonId: null,
        toCurrencyDefinitionId: null,
        conversionDate: new Date,
      }
    },

    onSubmitForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async isValid => {
        if (!isValid) return
        const payload = {
          currencyDefinitonId: this.form.currencyDefinitonId,
          toCurrencyDefinitionId: this.form.toCurrencyDefinitionId,
          conversionDate: moment(this.form.conversionDate).format('YYYY-MM-DD'),
          rate: this.form.rate
        }
        await this.createExchange(payload).then(res => {
          if (res) {
            const message = this.$t('Exchange.notification_Created')
            this.notifyShow({ message })
            if (this.saveAndNew) {
              this.resetForm()
              this.$nextTick(() => {
                this.$validator.reset()
              })
            } else {
              this.close()
            }
            this.$emit('getList')
          }
        })
      })
    }, 'submitExchangeForm')
  }
}
</script>

<style lang="scss" scoped>

  :deep() .icon-check {
    color: green !important
  }
  .success-check {
    position: absolute;
    right: 2%;
    top: 40%;
  }
  :deep() .select-dropdown-custom-selected {
    width: 100% !important;
  }
</style>
