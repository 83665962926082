export default function($t) {
  return [
    {
      name: 'source',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Exchange.source')
      },
      get title() {
        return $t('Exchange.source')
      }
    },
    {
      name: 'code',
      is_visible: true,
      get short_title() {
        return $t('Exchange.code')
      },
      get title() {
        return $t('Exchange.code')
      }
    },
    {
      name: 'currencyName',
      is_visible: true,
      get short_title() {
        return $t('Exchange.fromCurrencyName')
      },
      get title() {
        return $t('Exchange.fromCurrencyName')
      }
    },
    {
      name: 'toCurrencyName',
      is_visible: true,
      get short_title() {
        return $t('Exchange.toCurrencyName')
      },
      get title() {
        return $t('Exchange.toCurrencyName')
      }
    },
    {
      name: 'conversionDate',
      type: 'date',
      is_visible: true,
      get short_title() {
        return $t('Exchange.conversionDate')
      },
      get title() {
        return $t('Exchange.conversionDate')
      }
    },
    {
      name: 'rate',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Exchange.rate')
      },
      get title() {
        return $t('Exchange.rate')
      }
    }
  ]
}
