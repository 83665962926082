import HTTP from '@/utils/http'
import requestHandler from '@/utils/requestHandler'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,

  state: {
    ExchangeList: [],
    Page: {
      number: 1,
      size: 20,
      total: 0
    },
  },

  getters: {
    ExchangeList: state => state.ExchangeList,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    })
  },

  actions: {
    async getExchangeList({ commit, state }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      payload.pageNumber = PageNumber
      const loadingName = 'getExchangeList'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('ExchangeRate/all', payload)
        },
        success: result => {
          commit('SET_EXCHANGE_LIST', result.data)
          return result
        }
      })
      return results
    },

    async createExchange({ commit, dispatch }, payload) {
      const loadingName = 'createExchange'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('ExchangeRate', payload)
        },
        success: result => result
      })
      return results
    },

    async updateExchange({ commit, dispatch, state }, payload) {
      let updateExchangeRate = false
      state.ExchangeList.find(i => {
        if (i.id === payload.id) {        
          if (i.rate !== payload.rate) updateExchangeRate = true
        }
      })
      const loadingName = 'updateExchange'
      if (!updateExchangeRate) return
      const results = requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put('ExchangeRate', payload)
        },
        success: result => {
          dispatch('getExchange', payload.id)
          return result
        }
      })
      return results
    },

    async deleteExchange({ commit, dispatch }, Id) {
      const loadingName = 'deleteExchange'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.delete('ExchangeRate', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async getExchange({ commit }, id) {
      const loadingName = 'getExchange'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('ExchangeRate', { params: { id } })
        },
        success: result => {
          commit('SET_EXCHANGE', result.data.exchangeRate)
          return result
        }
      })
      return results
    }
  },

  mutations: {
    SET_EXCHANGE_LIST(state, data) {
      state.ExchangeList = data.exchangeRateList
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },

    SET_EXCHANGE(state, data) {
      const item = state.ExchangeList.find(i => i.id === data.id)
      item.rate = data.rate
    },
    
    RESET_EXCHANGE_LIST(state, data) {
      state.ExchangeList = []
      state.Page.number = 1
      state.Page.size = 20
      state.Page.total = 0
    }
  }
}
