var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [[_vm._v(" " + _vm._s(_vm.$t("Exchange.form_CreateTitle")))]],
        2
      ),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "form-exchange" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(_vm._s(_vm.$t("Global.fromCurrency"))),
                  ]),
                  _c("CustomSelect", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      name: "fromCurrency",
                      optionData: _vm.filteredCurrencyList,
                      optionTitle: "name",
                      selectedEmitValue: "id",
                      componentName: "exchange-from-currency-select",
                      isDefaultTextActive: false,
                      defaultTitle: _vm.$t("Register.formFieldCurrencySelect"),
                      "data-vv-as": _vm.$t("Global.fromCurrency"),
                      isSearchActive: true,
                      error: _vm.veeErrors.has("currency"),
                    },
                    model: {
                      value: _vm.form.currencyDefinitonId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "currencyDefinitonId", $$v)
                      },
                      expression: "form.currencyDefinitonId",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("currency"),
                        expression: "veeErrors.has('currency')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("currency") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(_vm._s(_vm.$t("Global.toCurrency"))),
                  ]),
                  _c("CustomSelect", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      name: "toCurrency",
                      optionData: _vm.filteredCurrencyList,
                      optionTitle: "name",
                      selectedEmitValue: "id",
                      componentName: "exchange-to-currency-select",
                      isDefaultTextActive: false,
                      defaultTitle: _vm.$t("Register.formFieldCurrencySelect"),
                      "data-vv-as": _vm.$t("Global.toCurrency"),
                      isSearchActive: true,
                      error: _vm.veeErrors.has("currency"),
                    },
                    model: {
                      value: _vm.form.toCurrencyDefinitionId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "toCurrencyDefinitionId", $$v)
                      },
                      expression: "form.toCurrencyDefinitionId",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("currency"),
                        expression: "veeErrors.has('currency')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("currency") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Exchange.popup_Date"))),
              ]),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("CustomDatepicker", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    class: { "is-danger": _vm.veeErrors.has(`exchangeDate`) },
                    attrs: {
                      name: "exchangeDate",
                      "data-vv-as": _vm.$t("Exchange.popup_Date"),
                      pickerType: "manuel",
                      inputIconRight: "icon-datepicker-big",
                      inputIcon: "",
                      disabledStartDate: _vm.disableDate,
                    },
                    model: {
                      value: _vm.form.conversionDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "conversionDate", $$v)
                      },
                      expression: "form.conversionDate",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("exchangeDate"),
                        expression: "veeErrors.has('exchangeDate')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("exchangeDate") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Exchange.popup_Rate"))),
              ]),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("custom-number-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "greater_than:0|required",
                        expression: "'greater_than:0|required'",
                      },
                    ],
                    staticClass: "rate-input",
                    attrs: {
                      id: "exchange-rate",
                      name: "rate",
                      "data-vv-as": _vm.$t("Exchange.popup_FormField_Rate"),
                      placeholder: _vm.$t("Exchange.popup_FormField_Rate"),
                      error: _vm.veeErrors.has("rate"),
                    },
                    model: {
                      value: _vm.form.rate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "rate", $$v)
                      },
                      expression: "form.rate",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("rate"),
                        expression: "veeErrors.has('rate')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("rate") },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "add-other-items-checkbox-container" },
            [
              _c("CustomCheckbox", {
                attrs: {
                  id: "checkbox-expense-save-and-new",
                  label: _vm.$t("Global.formSaveAndNewText", {
                    form: _vm.$t("Exchange.form_Name"),
                  }),
                },
                model: {
                  value: _vm.saveAndNew,
                  callback: function ($$v) {
                    _vm.saveAndNew = $$v
                  },
                  expression: "saveAndNew",
                },
              }),
            ],
            1
          ),
          _c(
            "Button",
            {
              attrs: {
                primary: "",
                id: "btn-exchange-form-submit",
                variant: "full",
                size: "medium",
                type: "submit",
                form: "form-exchange",
                disabled: _vm.isLoading,
                isLoading: _vm.isLoading,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [[_vm._v(_vm._s(_vm.$t("Exchange.form_FinishCreateButton")))]],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }