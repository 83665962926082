import Exchange from '../index'
import ExchangeForm from '../popup/form'
import ExchangeRemove from '../popup/remove'

export default [
  {
    path: '/exchange-rate',
    name: 'Exchange',
    component: Exchange,
    children: [
      {
        path: 'new',
        name: 'ExchangeNew',
        component: ExchangeForm
      },
      {
        path: ':id/edit',
        name: 'ExchangeEdit',
        component: ExchangeForm,
        props: {
          status: 'edit'
        }
      },
      {
        path: ':id/remove',
        name: 'ExchangeRemove',
        component: ExchangeRemove
      }
    ],
    meta: {
      main_menu: true,
      slug: 'exchange',
      tabPermissionKey: 'ExchangeRateMenu',
      page: 'exchange',
      category: 'Main_data'
    }
  }
]
